import { track } from '/machinery/track'

export function trackInteractionSlided({ index, title, action }) {
  return (
    track({ event: 'interaction',
      metadata: {
        interaction: {
          type: 'slider',
          action,
          index,
          title
        }
      }
    })
  )
}
